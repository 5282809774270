<template>
  <div class="service-panel">
    <Row v-if="this.power.indexOf('服务管理--提交服务纪录权限') > -1">
      <Col span="12">
        <div class="card">
          <div class="card-title">本次服务记录</div>
          <div class="card-body">
            <div style="display: flex;justify-content: flex-end;">
              <Select
                v-model="checked"
                class="card-select"
                placeholder="快捷语"
                @on-change="handleChange"
              >
                <Option v-for="item in shortcuts" :value="item" :key="item"></Option>
              </Select>
            </div>
            <Input
              v-model="serviceContent"
              type="textarea"
              :rows="7"
              placeholder="请输入..."
              class="card-textarea_left"
            ></Input>
          </div>
        </div>
      </Col>
      <Col span="8">
        <div class="card">
          <div class="card-title">会员问题记录</div>
          <div class="card-body">
            <Input
              v-model="problemContent"
              type="textarea"
              :rows="9"
              placeholder="请输入..."
              class="card-textarea_right"
            ></Input>
          </div>
        </div>
      </Col>
      <Col span="4">
        <div class="panel">
          <p class="panel-title">促进结果选择</p>
          <RadioGroup v-model="result" vertical>
            <Radio label="1">主动呼出未接听</Radio>
            <Radio label="2">有效接听3分钟以上</Radio>
            <Radio label="3">3分钟内异常中断</Radio>
          </RadioGroup>
        </div>
      </Col>
    </Row>
    <Row>
      <Col span="12" class="action-bar">
        <RadioGroup v-model="department">
          <Radio label="11">服务管理</Radio>
          <Radio label="8">方案部</Radio>
          <Radio label="15">教练部</Radio>
          <Radio label="10">专家部</Radio>
          <Radio label="9">促进部</Radio>
        </RadioGroup>
      </Col>
      <Col span="8" class="action-btn">
      <Button style="margin-right: 10px;" @click="handleBack">返回</Button>
        <Button type="success" @click="submit" :disabled="!result">提交</Button>
      </Col>
    </Row>
    <Table :columns="columns" :data="recordList" class="record-list"></Table>
    <p class="list-total">共{{ totalItems }}条记录</p>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page
          :total="totalItems"
          :current="searchData.page"
          :page-size="searchData.size"
          @on-change="handleChangePage"
        ></Page>
      </div>
    </div>
    <Modal
      :title="modalData.rq + '服务记录'"
      v-model="showModal"
      class-name="vertical-center-modal"
      width="900"
    >
      <Row>
        <Col span="14">
          <div class="card">
            <div class="card-title">服务记录</div>
            <div class="card-body">
              <div class="card-content">{{modalData.nr}}</div>
            </div>
          </div>
        </Col>
        <Col span="10">
          <div class="card">
            <div class="card-title">会员问题记录</div>
            <div class="card-body">
              <div class="card-content">{{modalData.wt}}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row class="modal-card">
        <Col span="24">
          <div class="card">
            <div class="card-title">解决方案</div>
            <div
              class="card-title card-title_status"
              :style="{color: modalData.zt === '0' ? 'red' : '#13ce66'}"
            >{{modalData.zt === '0' ? '未解决' : '已解决'}}</div>
            <div class="card-body">
              <div class="card-content">{{modalData.jjfa}}</div>
            </div>
          </div>
        </Col>
      </Row>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import ServiceRecordService from '@/services/serviceRecordService';
export default {
	data() {
		return {
			checked: '',
			shortcuts: [],
			serviceContent: '',
			problemContent: '',
			result: '',
			department: '',
			power: '',
			columns: [
				{ title: '日期', key: 'rq', align: 'center', width: 150 },
				{ title: '部门', key: 'bm', align: 'center', width: 150 },
				{ title: '服务员工', key: 'staff', align: 'center', width: 150 },
				{ title: '内容', key: 'nr', align: 'center' },
				{ title: '问题', key: 'wt', align: 'center' },
				{
					title: '状态',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h(
							'div',
							{
								style: {
									color: params.row.zt === '0' ? 'red' : '#13ce66',
								},
							},
							params.row.zt === '0' ? '未解决' : '已解决',
						);
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h(
							'a',
							{
								on: {
									click: () => {
										this.modalData = params.row;
										this.showModal = true;
									},
								},
								style: {
									color: '#ff8400',
								},
							},
							'查看',
						);
					},
				},
			],
			recordList: [],
			searchData: {
				page: 1,
				size: 10,
			},
			totalItems: 0,
			showModal: false,
			modalData: {},
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.getShortcuts();
		this.getRecordList();
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		getShortcuts() {
			ServiceRecordService.getShortcuts().then((data) => {
				this.shortcuts = data;
			});
		},
		handleChange(val) {
			this.serviceContent += val;
		},
		submit() {
			if (!this.department) {
				this.$Message.error('请选择部门');
				return;
			}
			const params = {
				member_id: this.$route.params.member_id,
				bcfwjl: this.serviceContent,
				hywtjl: this.problemContent,
				bm_id: this.department,
				jcjgxz: this.result,
			};
			ServiceRecordService.addRecord(params).then(() => {
				this.$Message.success('提交成功');
				window.location.reload();
			});
		},
		getRecordList() {
			this.searchData['member_id'] = this.$route.params.member_id;
			ServiceRecordService.recordList(this.searchData).then((data) => {
				this.recordList = data.list;
				this.totalItems = data.row_size;
			});
		},
		handleChangePage(page) {
			this.searchData.page = page;
			this.getRecordList();
		},
	},
};
</script>

<style lang="less" scoped>
.service-panel {
  padding: 20px 0;
  background-color: #fff;
}
.card {
  margin: 0 20px 20px;
  &-title {
    background-color: #507fe0;
    font-size: 16px;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &_status {
      background-color: #fff;
      border: 1px #e9e9e9 solid;
      border-bottom: none;
    }
  }
  &-body {
    height: 220px;
    border: 1px #e9e9e9 solid;
    padding: 10px;
  }
  &-select {
    width: 50%;
  }
  &-textarea_left {
    margin-top: 10px;
  }
}
.panel {
  margin: 0 10px 20px;
  &-title {
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.action-bar {
  padding: 0 20px 20px 20px;
}
.action-btn {
  text-align: right;
  padding-right: 20px;
}
.record-list {
  margin: 20px;
}
.list-total {
  color: #999;
  padding-right: 20px;
  text-align: right;
}
.modal-card .card-content {
  padding: 20px 0;
}
</style>
